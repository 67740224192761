import React from "react"

import Layout from "../components/Layout"
import Nav from "../components/Nav"
import SEO from "../components/seo"

const About = () => (
  <Layout contentWidth="narrow" id="home">
    <SEO title="About" />
    <p className="h5"><em>By losing ourselves in stories of the impossible, we wind our way to deeper truths. Get Lost and Bound with us.</em></p>

    <p><em>Lost and Bound</em> is a new themed quarterly digital lifestyle magazine of speculative identities, otherworldly non-fictions, and impossible realities. Each issue focuses on an undeserved fantastical community, telling their stories, exploring their needs, and presenting their beauty through fiction, non-fiction, erotica, games, crafts, recipes, fashion tips, and more. We aim to publish our first issue in the summer of 2021.</p>

    <p>Our first (!) issue celebrates <strong>symbionts and their hosts</strong>.</p>

    <Nav className="home" variant="cta" />
  </Layout>
)

export default About
